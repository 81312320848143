//             ["nextengine-scanner", "scanstudio", "nextwiki", "nextengine-website", "nextengine-comparo", "nextengine-order-system", "rapidworks", "nextengine-licensing"],

export default {
  "nextengine-scanner": {
    name: "nextengine-scanner",
    image: "nextengine/nextengine-scanner-left",
    theme: "light",
    aspectRatio: 0.55,
    title: "Desktop 3D Scanner",
    subtitle: "Hardware",
    /*button: { text: "Demo", video: "nextengine/jay-leno-demo" },*/
    button: { text: "Demo", video: "nextengine/nextengine-getting-started" },
    categories: ["hardware"],
    searchOrder: 2,
    description: "Best-selling 3D scanner in world. Used in 90+ countries for film, product design, manufacturing, art, education, science, research, forensics, and medicine.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "I led design and development of the software that powered the NextEngine 3D Scanner.  I also assisted with design and 3D rendering for next generation models of the scanner." },
    ],
  },
  "scanstudio": {
    name: "scanstudio",
    image: "nextengine/scanstudio-dog-ux",
    aspectRatio: 0.54,
    thumb: { resolution: 400, zoom: 1.9, x: 0.5, y: 0.42 },
    button: { text: "Demo", resolution: "original", video: "nextengine/nextengine-3d-scanner-demo" },
    theme: "light",
    title: "ScanStudio",
    subtitle: "App",
    categories: ["app"],
    description: "ScanStudio combines an easy to learn UI with powerful machine intelligence.  Handle massive 3D point clouds, and provides tools for scanning, assembly, editing, and measurement.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "I led the design and development of ScanStudio, and worked with a very talented team of engineers and artists." },
    ],
  },
  "nextwiki": {
    name: "nextwiki",
    image: "nextengine/nextwiki",
    theme: "light",
    aspectRatio: 0.59,
    thumb: { resolution: 400, zoom: 3.4, x: 0.775, y: 0.34 },
    title: "Support System",
    subtitle: "App, Cloud",
    categories: ["app", "cloud"],
    description: "An early Intercom-style realtime chat system, built into ScanStudio.  It made it easy for users to show us their scans, and our staff to share tips and knowledge-base articles.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "After completing ScanStudio, and the NextEngine website, I led development and design of the NextWiki Support System, working with a talented team of engineers and artists to bring it to life.  This system was essential to educating users, and making sure they had the support they needed to have a positive initial experience with their scanner."},
      { text: "This investment in high-grade support paid massive dividends: the NextEngine brand became widely known through word of mouth, with no need for advertising." },
    ],
  },
  "nextengine-website": {
    name: "nextengine-website",
    image: "nextengine/website-redesign",
    theme: "light",
    aspectRatio: 0.55,
    title: "NextEngine Website",
    subtitle: "Web",
    categories: ["web"],
    description: "Showcases the NextEngine scanner, high resolution 3D scans, case studies from users in the field, and TV and films where the scanner has appeared.",
    button: { text: "Demo", url: "https://nextengine.com/" },
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "After building the first version of ScanStudio, I launched an effort to create our company website.  The website evolved over time, and was built with the help of a small, talented team of engineers and artists." },
      { if: "pete", text: "During my last few months at NextEngine, I led an effort to upgrade the site, and designed the responsive website concept shown above." },
    ],
  },
  "nextengine-order-system": {
    name: "nextengine-order-system",
    image: "nextengine/scanner-order-system",
    theme: "light",
    aspectRatio: 0.55,
    title: "Order System",
    subtitle: "Web, Cloud",
    categories: ["web", "cloud"],
    button: { text: "Demo", url: "https://nextengine.com/buy" },
    description: "Simple single-page checkout process, backed by a secure PCI-compliant order system.  Seamlessly integrated with our SW licensing system, and an iPhone app that streams sales info.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "I led development and design of the order system, working with a talented team of engineers and artists to bring it to life." },
    ],
  },
  "nextengine-comparo": {
    name: "nextengine-comparo",
    image: "nextengine/comparison-dog-top-bar",
    theme: "light",
    aspectRatio: 0.69, /*0.66*/
    thumb: { resolution: 400, zoom: 1.32, x: 0.23, y: 0.3 },
    /*thumb: { resolution: 400, zoom: 1, x: 0, y: 0.08 },*/
    title: "3D Comparison Tool",
    subtitle: "Web",
    categories: ["web"],
    button: { text: "Demo", url: "http://3dscanners2018.com" },
    description: "Highlights the quality difference between NextEngine scan data and competing products.  Users drag their finger to see the same shape, as captured by different 3D scanners.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { text: "After being the only affordable scanner for a number of years, cheap low quality scanners began to flood the market.  These scanners had very high return rates, but to continue to grow our sales, we needed a way to educate users on the dramatic quality difference with our scanner.  The comparison tool was essential in helping us maintain our industry lead."},
      { if: "pete", text: "I designed the comparison tool based on input from our CEO and marketing team, and built it in a 2 week coding binge.  It was developed using JavaScript, HTML5, and CSS." },
    ],
  },
  "rapidworks": {
    name: "rapidworks",
    image: "nextengine/rapidworks",
    theme: "light",
    aspectRatio: 0.56,
    title: "RapidWorks",
    subtitle: "CAD Software",
    categories: ["app"],
    button: { text: "Demo", url: "https://vimeo.com/206260446" },
    description: "RapidWorks is a reverse engineering app co-developed with partner RapidForm.  Powerful AI-assisted tools help the user to convert a 3D scan into a parametric CAD model.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "RapidWorks was based on RapidForm, a powerful but complex project with hundreds of buttons and functions.  I helped RapidForm to re-organize their app into a simple 6 step process, with the functions relative to each process available at the appropriate time.  This made it easier for new users to learn RapidWorks." },
    ],
  },
  "nextengine-licensing": {
    name: "nextengine-licensing",
    image: "nextengine/nextwiki-licensing-screen",
    theme: "light",
    aspectRatio: 0.60,
    thumb: { resolution: 400, zoom: 1.51, x: 0, y: 0.519 },
    title: "Licensing System",
    subtitle: "Cloud",
    categories: ["cloud"],
    description: "One of the 1st cloud-based software licensing systems. At the time, most SW licensing in the industry used dongles. Support staff could give and extend trials with a click.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "I designed and built the licensing system using digital signatures and public / private key cryptography. It worked securely both online and offline. Later versions could also communicate with an attached 3D scanner for authorization." },
    ],
  },
  "photo-chat-patent": {
    name: "photo-chat-patent",
    image: "nextengine/photo-chat-patent",
    theme: "light",
    /*aspectRatio: 0.55,*/
    /*aspectRatio: 0.6,
    thumb: { resolution: 340, zoom: 1, x: 0, y: 0 },*/
    title: "Integrated Support Chat",
    subtitle: "US Patent 9021041",
    categories: ["patent"],
    extraSearchCategories: ["app"],
    button: { text: " PDF ", url: "/patents/US9021041B2.pdf" },
    description: "An early Intercom-style realtime chat capability, built into an app.  Users browsed topics in a knowledge base, and could ask a question at any time.  The linked topic provided context.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "This patent was based on the NextWiki Support System which my team built.  I assisted the legal team with writing the patent claims and embodiment." },
    ],
  },
  "handheld-scanner-patent": {
    name: "handheld-scanner-patent",
    image: "nextengine/handheld-scanner-patent",
    theme: "light",
    aspectRatio: 0.55,
    title: "Handheld 3D Scanner",
    subtitle: "US Patent 9549168", /*9549168*/ /*8116559*/
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/US9549168.pdf" },
    description: "A method for capturing and combining multiple photos at different focal lengths, to create a 3D depth map and a perfectly focused image.  Used in modern smartphones.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "The handheld 3D scanner was a technology and series of prototypes that we developed at NextEngine.  The handheld scanner prototype used high resolution imaging combined with multiple exposures at different focal lengths.  The result was a composite image with perfect focus at every pixel, and the a 3D depth map based on the focal length at which each pixel was at its ideal focus." },
      { text: "Modern smartphones including the iPhone X and XS now use this technique of compositing multiple images at different focal lengths.  Like our handheld 3D scanner, they also produce 3D depth maps." },
      { text: "Note: The optical mouse demostrated that when viewed closely enough, almost every surface has minute detail which can be used for measurement.  The handheld scanner operates on a similar principle." },
      { if: "pete", text: "I worked on conceptual design for the handheld 3D scanner, and researched focal, pattern based, and stereoscopic techniques we could use to infer depth data.  I helped the legal team to write the claims and embodinement for the patent." },
    ],
  },
  "integrated-capture-patent": {
    name: "integrated-capture-patent",
    image: "nextengine/integrated-capture-patent",
    theme: "light",
    aspectRatio: 0.55,
    title: "Integrated 3D Capture",
    subtitle: "US Patent 8049751", /*9619932*/
    categories: ["patent"],
    extraSearchCategories: ["app"],
    button: { text: " PDF ", url: "/patents/US8049751.pdf" },
    description: "This patent explores the integration of 3D scanning capability into applications such as CAD, search engines, realtime collaboration, email, and a 3D copier or fax machine.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "This patent explores the integration of 3D scanning capability into existing software applications, transforming their utility by adding an input from our real 3D world." },
      { text: "Applications detailed in the patent include CAD, search engines, email, realtime collaboration, and a 3D copier / fax machine." },
      { if: "pete", text: "I did design work on several possible ScanStudio extensions with these features, and also helped to write the patent claims and embodiment." },
    ],
  },
  "shape-search-patent": {
    name: "shape-search-patent",
    image: "nextengine/shape-search-patent",
    theme: "light",
    aspectRatio: 0.55,
    title: "Shape Search Engine",
    subtitle: "US Patent 8126907",
    categories: ["patent"],
    button: { text: " PDF ", url: "/patents/US8126907.pdf" },
    description: "Think Google for 3D objects.  This comprehensive patent covers searching for shapes, products, and information using a scanned 3D shape as input.  Advertising is covered too.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "I worked on conceptual designs for a shape search engine powered by NextEngine 3D scan data. I assisted the legal team with writing the claims and embodiment for the patent." },
    ],
  },




};
