// src/Home.js
import React from 'react';
import {
  View,
  ScrollView,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
  Easing,
  Animated,
  Dimensions
} from 'react-native';
import { connect } from 'react-redux'
import Sticky from 'components/utility/Sticky';
import MenuStrip from 'components/strips/MenuStrip';
import ProjectCardStrip from 'components/strips/ProjectCardStrip';
import ProjectCard from 'components/cards/ProjectCard';
import ActiveCard from 'components/cards/ActiveCard';
import Hamburger from 'components/buttons/Hamburger';
import HamburgerOverlay from 'components/overlays/HamburgerOverlay';
import HigherGradient from 'components/utility/HigherGradient';
import PersonSection from 'components/sections/person/PersonSection';
import PersonTalkSection from 'components/sections/person/PersonTalkSection';
import ResumeSection from 'components/sections/person/ResumeSection';
import SkillSection from 'components/sections/person/SkillSection';
import AboutSection from 'components/sections/person/AboutSection';
import FooterSection from 'components/sections/shared/FooterSection';
import PortfolioLinkSection from 'components/sections/links/PortfolioLinkSection';
import PeopleLinkSection from 'components/sections/links/PeopleLinkSection';
import SimpleLinkSection from 'components/sections/links/SimpleLinkSection';
import LogoSection from 'components/sections/home/LogoSection';
import PeopleSection from 'components/sections/home/PeopleSection';
import TalkSection from 'components/sections/home/TalkSection';
import TextSection from 'components/sections/shared/TextSection';
import ProjectSection from 'components/sections/shared/ProjectSection';
import CategoryStrip from 'components/strips/CategoryStrip';

import SectionHeader from 'components/headers/SectionHeader';

import FullScreen from 'components/utility/FullScreen';
import HigherSectionList from 'components/utility/HigherSectionList'

import PEOPLE from 'constants/people';
import CATEGORIES from 'constants/categories';
import PROJECTS from 'constants/projects';
import TEXT from 'constants/text';
import { chooseMenu } from 'state/actions/menuActions.js'
import { chooseCategory } from 'state/actions/categoryActions.js'
import { openHamburger, closeHamburger } from 'state/actions/hamburgerActions.js'
import ReactDOM from 'react-dom';
import Resizable from 'components/utility/Resizable';

class SectionPage extends Resizable {

  // Track the window size
  state = { ...Dimensions.get("window"),
            stuckSection: {},
            scroll: 0 };

  componentWillMount() {
    this.hearResize();
  }

  componentWillUnmount() {
    this.muteResize();
  }

  resetMainScroll(){
    if (this._mainList) {
      this._mainList.scrollToLocation({
        sectionIndex: 0,
        itemIndex: 0,
        animated: true
      });
    }
  }

  gotoHamburgerLink(name) {
    this.props.closeHamburger();
    this.gotoSectionWithName(name);
    this.highlightSection({ name: name });

    // Tag when we hide the menu
    window.gtag('event', "Jump", { 'event_category': "Section", 'event_label': name });
  }

  gotoSectionWithName(name){
    this._mainList.scrollToSectionWithName(name);
  }

  onHamburger() {
    if ( this.props.hamburger.active ) {
      this.props.closeHamburger();

      // Tag when we hide the menu
      window.gtag('event', "Hide", { 'event_category': "Menu" });
    }
    else {
      this.props.openHamburger();
      if ( this.props.selectedCategoryName ) {
        setTimeout(() => {
          this.props.chooseCategory(undefined);
          this._mainList.forceRefresh(); // Fernando Mora: need this in order to update main list view positions.
        }, 300);
      }
      // Tag when we show the menu
      window.gtag('event', "Show", { 'event_category': "Menu" });
    }
  }

  highlightSection(section) {

    // Lookup the person
    if ( this.props.menu && section ) {

      // Chose the menu
      this.props.chooseMenu(this.props.menu.index[section.menu || section.name]);

      // See if it's the top section
      if ( section.name === "top" && this.props.selectedCategoryName ) {
        setTimeout(() => {
          this.props.chooseCategory(undefined);
          this._mainList.forceRefresh(); // Fernando Mora: need this in order to update main list view positions.
        }, 300);
      }

      // Tag whenever a filter is unapplied
      window.gtag('event', "Reveal", { 'event_category': "Section", 'event_label': section.menu || section.name });
    }
  }

  onStuckSectionChange(section) {
    this.setState({ stuckSection: section });
  }

  onSectionTitlePress(title) {
    this.onHamburger();
  }

  onScroll(event) {

    // Is it the mobile version?
    const mobile = this.state.width < 700;
    if ( mobile ) {

      // Get the y position, and update our state and animation with it
      const y = event.nativeEvent.contentOffset.y;
      this.setState({ scroll: Math.min(y, 350) });
    }
  }

  render() {

    // Handle category presses
    const onCategoryPress = category => {

      // Make sure to show top of portfolio cards
      this.gotoSectionWithName("portfolio");

      // Force scrollist refresh
      this._mainList.forceRefresh();
      //setTimeout(() => { this._mainList.forceRefresh(); }, 300);
    };

    // Called when someone pushes the section title
    // It's as if someone pushes the hamburger button
    const onSectionTitlePress = title => {
      this.onSectionTitlePress(title);
    };

    // Called when someone pushes the section title
    // It's as if someone pushes the hamburger button
    const onHamburger = () => {
      this.onHamburger();
    };


    // Is it the mobile version?
    const mobile = this.state.width < 700;

    // Do we need a hamburger overlay?
    let hamburger = <View/>;
    let hamburgerOverlay = <View/>;
    let menuStrip = <View/>;

    // Are we mobile?
    if ( mobile ) {

      // For mobile we need a hamburger button
      hamburger = <Hamburger active={this.props.hamburger.active} style={{ position: "absolute", left: 20, top: 20, zIndex: 100 }} width={25} height={25} color="#C0BFBF" onPress={onHamburger}/>;

      // Fill out the hamburger overlay for the person
      if ( this.props.hamburger && this.props.hamburger.active ) {

        // Is it the personal version or team one?
        if ( this.props.personName ) {
          hamburgerOverlay = <HamburgerOverlay active={this.props.hamburger.active}>
            <PortfolioLinkSection person={this.props.personName}
                                  onImagePress={() => { this.gotoHamburgerLink("top") }}
                                  onPortfolioPress={() => { this.gotoHamburgerLink("portfolio") }}/>
            <CategoryStrip background="#ffffff" categories={this.props.categories} onCategoryPress={onCategoryPress}/>
            <View style={{ height: 1, marginTop: 10, backgroundColor: "#DBDBDB"}}/>
            <SimpleLinkSection title="CV" line onPress={() => { this.gotoHamburgerLink("cv") }}/>
            <SimpleLinkSection title="Skills" line onPress={() => { this.gotoHamburgerLink("skills") }}/>
            <SimpleLinkSection title="About" line onPress={() => { this.gotoHamburgerLink("about") }}/>
            <SimpleLinkSection title="Talk" onPress={() => { this.gotoHamburgerLink("talk") }}/>
          </HamburgerOverlay>;
        }
        else {
          hamburgerOverlay = <HamburgerOverlay active={this.props.hamburger.active}>
            <PortfolioLinkSection onImagePress={() => { this.gotoHamburgerLink("top") }}
                                  onPortfolioPress={() => { this.gotoHamburgerLink("portfolio") }}/>
            <CategoryStrip background="#ffffff" categories={CATEGORIES.sequence} onCategoryPress={onCategoryPress}/>
            <View style={{ height: 1, marginTop: 10, backgroundColor: "#DBDBDB"}}/>
            <SimpleLinkSection title="Approach" line onPress={() => { this.gotoHamburgerLink("approach") }}/>
            <SimpleLinkSection title="Talk" onPress={() => { this.gotoHamburgerLink("talk") }}/>
          </HamburgerOverlay>;
        }
        /*            <PeopleLinkSection title="People" line onPress={() => { this.gotoHamburgerLink("people") }}/>
*/
      }
    }
    else {
      menuStrip = <MenuStrip ref={component => this._mainMenu = component}
                             menu={this.props.menu}
                             onMenuPress={(item)=>{this.gotoSectionWithName(item.name)}}/>
    }

    // How much of the person section do we show?
    let personOpacity = 1;
    if ( mobile && this.state.scroll > 150 ) {
      personOpacity = 1 - Math.min((this.state.scroll - 150) / 100, 1);
    }

    // Native version
    return (
      <FullScreen ref={component => this._fullScreen = component}>
        <View style={{ height: "100%", marginTop: 0, width: "100%", backgroundColor: "#f4f4f4"}}>
          {hamburgerOverlay}
          {hamburger}
          {menuStrip}
          <HigherSectionList style={{ width:"100%"}}
            stickySectionHeadersEnabled={true}
            ref={component => this._mainList = component}
            onSectionChange={(section)=>{this.highlightSection(section)}}
            onStuckSectionChange={(section)=>{this.onStuckSectionChange(section)}}
            onScrollList={(event)=>{this.onScroll(event)}}
            renderSectionHeader={({section}) =>{

              // Is this action?
              const sectionIsAtTop = this.state.stuckSection && ((this.state.stuckSection.title === section.title) ||
                               (section.lastLineOfDefense && this.state.stuckSection.showLineOnJump));

              // Here's the section header
              return <SectionHeader mobile={this.state.width < 700}
                                    personName={this.props.personName}
                                    categories={this.props.categories}
                                    onTitlePress={onSectionTitlePress}
                                    onCategoryPress={onCategoryPress}
                                    showLine={sectionIsAtTop}
                                    title={section.title}
                                    description={this.props.description}
                                    scroll={section.title === "Categories" ? this.state.scroll : undefined}/>
            }}
            renderItem={({item, index, section}) => {
              switch (section.title) {
                case "Logo":
                  return <LogoSection showDescription={!mobile} opacity={personOpacity} history={this.props.history} onPress={onHamburger} description={this.props.description}/>;
                case "Person":
                  return <PersonSection showDescription={!mobile} person={this.props.personName} opacity={personOpacity} onPress={onHamburger}/>
                case "Categories":
                  return <ProjectSection mobile={this.state.width < 700}
                                         personName={this.props.personName}
                                         selectedCategoryName={this.props.selectedCategoryName}
                                         projects={this.props.projects}
                                         history={this.props.history}
                                         fadeColor={this.props.personName ? undefined : "#ffffff"}/>
                case "Story":
                  return <TextSection title="Story" showHeader={!mobile} history={this.props.history} paragraphs={TEXT.story.paragraphs}/>;
                case "Approach":
                  return <TextSection title="Approach" showHeader={!mobile} history={this.props.history} paragraphs={TEXT.approach.paragraphs}/>;
                case "People":
                  return <PeopleSection showHeader={!mobile} history={this.props.history} description="Passion meet precision.  Veteran 10xers."/>
                case "CV":
                  return <ResumeSection showHeader={!mobile} person={this.props.personName}/>
                case "Skills":
                  return <SkillSection showHeader={!mobile} person={this.props.personName}/>
                case "About":
                  return <AboutSection showHeader={!mobile} mobile={mobile} person={this.props.personName}/>
                case "Talk":
                  if ( this.props.personName ) {
                    return <PersonTalkSection showHeader={!mobile} person={this.props.personName} history={this.props.history}/>
                  }
                  else
                  {
                    return <TalkSection showHeader={!mobile} history={this.props.history}/>;
                  }
                case "Footer":
                  return <FooterSection footer={this.props.footer} history={this.props.history}/>
                default:
                  return <View style={{ height: 0 }}/>;
              }
            }}
            sections={this.props.sections}
            keyExtractor={(item, index) => item + index}
          />
          <ActiveCard history={this.props.history} personName={this.props.personName}/>
        </View>
      </FullScreen>
    );

  }
};

/*

<Sticky bottomBoundary=".stickyStop">
</Sticky>
*/

const childProps = (state, parentProps) => ({
  ...parentProps,
  selectedCategoryName: state.category,
  hamburger: state.hamburger,
  activeCard: state.card,
});

const childDispatch = (dispatch) => {
    return {
      chooseMenu: (menu, opts) => dispatch(chooseMenu(menu, opts)),
      chooseCategory: (category) => dispatch(chooseCategory(category)),
      openHamburger: (hamburger) => dispatch(openHamburger(hamburger)),
      closeHamburger: (hamburger) => dispatch(closeHamburger(hamburger)),
    };
};

export default connect(
  childProps,
  childDispatch
)(SectionPage);
