import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions
} from 'react-native';
import ProjectCard from 'components/cards/ProjectCard';

class ProjectCardStrip extends React.Component {

  // Detect a change
  componentDidUpdate(prevProps, prevState) {
    if ( prevProps.selectedCategoryName != this.props.selectedCategoryName ) {
      this.scrollToStart();
    }
  }

  // Scroll to the start of the project card strip
  scrollToStart() {
    if ( this.flatList ) {
      this.flatList.scrollToOffset({ animated: true, offset: 0 });
    }
  }

  // Render everything
  render() {
    const { width, height } = Dimensions.get('window');
    const smallerWidth = parseInt(width * 0.84, 10);
    return (
      <View style={{ width: "100%", marginTop: -15, paddingTop: 0, marginBottom: -25, overflow: "hidden" }}>
        <FlatList
          ref={flatList => this.flatList = flatList}
          contentContainerStyle={{ paddingBottom: 10 }}
          style={{ width: "100%", paddingTop: 15, paddingLeft: 10, paddingRight: 10, paddingBottom: 15}}
          pagingEnabled={true}
          snapToInterval={smallerWidth}
          snapToAlignment={"center"}
          decelerationRate={0}
          horizontal
          showsHorizontalScrollIndicator={false}
          keyExtractor={card => card ? card.name : "none"}
          data={this.props.cards}
          renderItem={({ item }) => {
            if ( item ) {
              return <View key={item.name} style={{ scrollSnapAlign: "center" }}><ProjectCard history={this.props.history}
                                 radius={10}
                                 card={item}
                                 personName={this.props.personName}
                                 mobile={this.props.mobile}
                                 selected={item.name === this.props.selectedProjectName}/></View>;
            }
            else {
              return <View/>;
            }
          }}
        />
      </View>
    );
  }
};

export default ProjectCardStrip;
