/*https://res.cloudinary.com/higher-design/image/upload/v1551630385/categories/*/

import React from 'react';
import TextBladeProject from 'components/projects/TextBladeProject';

import UPGIFT from "constants/projects/upgift"
import WAYTOOLS from "constants/projects/waytools"
import NEXTENGINE from "constants/projects/nextengine"
import PERSONAL from "constants/projects/personal"
import STORYJAR from "constants/projects/storyjar"
import MAGBAK from "constants/projects/magbak"
import TESLA from "constants/projects/tesla"
import DAQRI from "constants/projects/daqri"

export default {
    index: {
      ...UPGIFT,
      ...WAYTOOLS,
      ...NEXTENGINE,
      ...PERSONAL,
      ...STORYJAR,
      ...MAGBAK,
      ...TESLA,
      ...DAQRI,
    },
    rows: [["textblade-keyboard", "textblade-app", "textblade-website", "textblade-order-system",  "remote-scopes",  "realtime-device-sync", "textblade-video", "hands-on-video", "universal-keyboard-video", "multimap-video", "android-video", "textblade-international-layouts", "fingerbot", "forcebot"],
           ["nextengine-scanner", "scanstudio", "nextengine-website", "nextengine-comparo", "rapidworks", "nextengine-order-system", "nextwiki"],
           ["magbak-case", "magbak-wallet", "model3", "daqri", "upgift-app", "upgift-website", "upgift-video", "upgift-payments", "upgift-social-network"],
           ["textblade-patent", "handheld-scanner-patent", "photo-chat-patent", "integrated-capture-patent", "shape-search-patent", "textblade-design-patent", "textblade-cursor-patent", "textblade-modkeys-patent", ],
          ]
}
