export default {
  "daqri": {
    name: "daqri",
    image: "daqri/goggles",
    theme: "light",
    aspectRatio: 0.55,
    title: "DAQRI Smart Glasses",
    subtitle: "Hardware",
    /*button: { text: "Demo", video: "nextengine/jay-leno-demo" },*/
    //button: { text: "View", url: "https://1lss.com/" },
    categories: ["hardware"],
    searchOrder: 5,
    description: "Designed AR glasses and input devices for DAQRI, the leader in professional grade AR glasses.  DAQRI smart glasses provide more power than HoloLens in a smaller form factor.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
    ],
  },
};
