export default {
  story: {
    paragraphs: [
      "We got our start together in Santa Monica, creating an affordable and high accuracy 3D scanner that quickly became the world standard.  Our aim was to democratize the creation of 3D content and allowing for more efficient manufacturing.  And that dream was realized: from New Balance shoes, to Harry Potter movies, to doctor offices, to the Smithsonian institute imaging new hominid ancestors on location, our scanner was there.",
      "Hooked on creating game changing inventions, we turned to the burgeoning mobile revolution.  We invented the world's smallest keyboard, which paradoxically was also one of the easiest and most comfortable to type on.",
    ],
  },
  approach: {
    paragraphs: [
      "Design thinking can transform challenging problems into amazing new opportunities.",
      /*We love being the first to solve brave new problems. */
      "Our team's process marries the analytical with the aesthetic, and the intellect with inspiration, to elegantly solve problems that were previously intractable.",
      "We believe that power and simplicity are not mutually exclusive. Thoughtful design can deliver sophisticated capabilities in an experience that feels simple and joyful.",
      "We drive designs forward through a combination of deep thought experiments, exciting visual mockups, usable rapid prototypes, and user testing.",
      /*"We love being the first to solve brave new problems.  We study those that have come before, and steer the ship deeper.",*/
      /*"When design goals and benefits are precisely articulated, they become easy to evaluate objectively. ",*/
      /*"We believe that design is a practice that works best when all ideas are welcome, and all viewpoints are honored.  Insight can come from anywhere, and every single person, situation, and interaction can be a powerful teacher – if we listen.",*/
      /*When design goals and benefits are precisely articulated, they become easy to evaluate objectively.  */
    ],
  },
};

/* Einstein was a big believer in the power of the thought experiment.*/
