//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Image,
  Text,
  StyleSheet,
} from 'react-native';
import FooterItem from 'components/items/FooterItem'

export default class FooterSection extends React.Component {

  render() {

    // Map the item names to the items in the index
    const items = this.props.footer.sequence.map((itemName) => { return this.props.footer.index[itemName]; });

    // Show our section
    return (
      <View style={styles.section}>
        <View style={styles.line}/>
        <View style={{ height: 25 }}/>
        <View style={[styles.container, { maxWidth: this.props.footer.columnWidth ? this.props.footer.columnWidth * 2 : undefined }]}>
          {items.map((item, index) => {
            return <View key={index} style={[styles.item, { minWidth: this.props.footer.columnWidth }]}><FooterItem item={item}/></View>
          })}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  section: {
    backgroundColor: "#f4f4f4",
    paddingBottom: 15,
    width: "100%",
    minHeight: 150
  },
  line: {
    height: 1,
    backgroundColor: "#DBDBDB",
  },
  container: {
    maxWidth: 500,
    flexDirection: "row",
    flexWrap: "wrap",
    alignSelf: "center",
    justifyContent: "center",
    marginLeft: 20,
    marginRight: 20,
  },
  item: {
    alignSelf: "flex-start",
    minWidth: 250,
    marginBottom: 15,
  }
});
