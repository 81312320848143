//             ["nextengine-scanner", "scanstudio", "nextwiki", "nextengine-website", "nextengine-comparo", "nextengine-order-system", "rapidworks", "nextengine-licensing"],

export default {
  "magbak-case": {
    name: "magbak-case",
    image: "magbak/magbak-case",
    theme: "light",
    aspectRatio: 0.55,
    title: "MagBak Case",
    subtitle: "Hardware",
    /*button: { text: "Demo", video: "nextengine/jay-leno-demo" },*/
    button: { text: "View", url: "https://1lss.com/" },
    categories: ["hardware"],
    searchOrder: 3,
    description: "A minimalist protective case and magnetic mounting solution for smartphones.  Users can instantly attach their phone to their car, kitchen, or anywhere.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
    ],
  },
  "magbak-wallet": {
    name: "magbak-wallet",
    image: "magbak/magbak-wallet-stand-typing",
    /*    image: "magbak/magbak-wallet-hand-loop",
*/
    theme: "light",
    aspectRatio: 0.55,
    title: "MagBak Wallet",
    subtitle: "Hardware",
    /*button: { text: "Demo", video: "nextengine/jay-leno-demo" },*/
    button: { text: "View", url: "https://1lss.com/" },
    categories: ["hardware"],
    searchOrder: 3,
    description: "Streamlined leather wallet magnetically auto-aligns and securely attaches to smartphone. Acts as a phone stand and finger loop, and includes RFID protection.",
    content: [
      { description: true },
      { photo: "projects/magbak/magbak-wallet-hand-loop.jpg", aspect: 2048 / 1108 },
      /*{ photo: "projects/magbak/magbak-wallet-stand-typing.jpg", aspect: 2048 / 1108 },*/
    ],
  },




};
