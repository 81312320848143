export default {
  "upgift-app": {
    name: "upgift-app",
    image: "upgift/app-tropical",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 1,
    title: "UpGift App",
    subtitle: "iOS, Android, Web",
    categories: ["app"],
    button: { text: "Install", url: "https://itunes.apple.com/us/app/upgift/id1385278199" },
    description: "Consumer app makes it easy to give group gifts including: classes, concert tickets, trips, and experiences. UX designed for 3 generations. Powered by a single universal React codebase.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "UpGift is the first platform for group gifting experiences. UpGift combines the power of crowdfunding, with a social network built around the people and goals that matter, to transform the way we give." },
      { text: "UpGift’s mission is to help people live fuller lives, and free our culture and planet from peak-materialism." },
      { if: "pete", text: "I designed and led development of the UpGift app.  The app was built using React with a thin native wrapper for iOS and Android.  This allowed us to support all platforms from a single code base." },
    ],
  },
  "upgift-website": {
    name: "upgift-website",
    image: "upgift/website-wave",
    theme: "dark",
    aspectRatio: 0.55,
    title: "UpGift Website",
    subtitle: "Responsive, React Site",
    categories: ["web"],
    button: { text: "Demo", url: "https://upgift.com" },
    description: "Responsive website educates visitors about UpGift and converts 20% to signups.  Website smoothly segues into a full app-like experience without leaving the browser or Facebook.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "Responsive website educates visitors about UpGift and converts 20% to signups.  Website smoothly segues into a full app-like experience without leaving the browser or Facebook." },
      { if: "pete", text: "I designed and led development of the UpGift website.  The website was built using React, JavaScript, and CSS.  The site's design evolved over time, as we leveraged insights from data on user behavior to boost conversion rate." },
    ],
  },
  "upgift-video": {
    name: "upgift-video",
    image: "upgift/upgift-give-video",
    /*image: "upgift/upgift-app-video",*/
    /*image: "upgift/give-experiences-video",*/
    theme: "light",
    aspectRatio: 0.55,
    thumb: { resolution: 400, zoom: 1.65, x: 0.4, y: -0.05 },
    title: "Give Experiences!",
    subtitle: "Video",
    categories: ["video"],
    video: "upgift-app-wide-1080",
    button: { text: "Watch", video: "upgift-app-wide-1080", loop: true },
    description: "A 15-second looping video ad that introduces new audiences to UpGift. The video yielded a 45% conversion rate from Facebook viewers to UpGift website visitors.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "A 15-second looping video ad that introduces new audiences to UpGift. The video yielded a 45% conversion rate from Facebook viewers to UpGift website visitors." },
      { if: "pete", text: "I scripted the video and created it using Adobe After Effects, Premiere, Illustrator, and XD." },
    ],
  },
  "upgift-payments": {
    name: "upgift-payments",
    image: "upgift/group-gifting",
    theme: "light",
    aspectRatio: 0.55,
    title: "UpGift Group Payments",
    subtitle: "Cloud",
    categories: ["cloud"],
    description: "Group payment platform leverages Stripe and accepts Apple Pay, Google Pay, Venmo, and credit or debit card.  Gift recipients can link their bank account to claim gifts.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "Group payment platform leverages Stripe and accepts Apple Pay, Google Pay, Venmo, and credit or debit card.  Gift recipients can link their bank account to claim gifts." },
      { text: "Built with Amazon Web Services and infinitely scalable with 100% serverless cloud architecture." },
    ],
  },
  "upgift-social-network": {
    name: "upgift-social-network",
    image: "upgift/social-network",
    theme: "light",
    aspectRatio: 0.55,
    title: "UpGift Social Network",
    subtitle: "Cloud",
    categories: ["cloud"],
    description: "Gifting means “you matter to me”.  Those we gift with form our tightest social network.  UpGift helps users share dreams, gratitude, photos, and updates with those that matter most.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "Gifting means “you matter to me”.  Those we gift with form our tightest social network.  UpGift helps users share dreams, gratitude, photos, and updates with those that matter most." },
      { text: "Built with Amazon Web Services and infinitely scalable with 100% serverless cloud architecture." },
    ],
  },
  "upgift-search": {
    name: "upgift-search",
    image: "upgift/experience-search",
    theme: "light",
    title: "Experiences Search",
    subtitle: "API Integrations",
    categories: ["cloud"],
    /*button: { text: "Demo", url: "https://upgift.com/ideas" },*/
    button: { text: "Demo", video: "upgift-ideas" },
    description: "Discover awesome ideas, all in one place.  UpGift works with Yelp and Eventbrite to show experiences nearby.  Local businesses can also register directly on UpGift.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "Discover awesome ideas, all in one place.  UpGift works with Yelp and Eventbrite to show experiences nearby.  Local businesses can also register directly on UpGift." },
      { text: "Built with Amazon Web Services and infinitely scalable with 100% serverless cloud architecture." },
    ],
  },
  "upgift-brand": {
    name: "upgift-brand",
    image: "upgift/app-icon", /*"upgift-icon-stars2",*/
    theme: "light",
    aspectRatio: 0.55,
    title: "UpGift Branding ",
    subtitle: "Logo, App Icon",
    categories: ["brand"],
    description: "We wish on stars.  We aim for them.  Our icon marries this cultural context with a novel upward momentum.  UpGift is an aspirational brand that helps people reach their dreams.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "We wish on stars.  We aim for them.  Our icon marries this cultural context with a novel upward momentum.  UpGift is an aspirational brand that helps people reach their dreams." },
      { if: "pete", text: "I created the UpGift name, logo, and app icon.  Adobe Illustrator was used for logo and icon design." },
    ],
  },
  "santa-ads": {
    name: "santa-ads",
    image: "upgift/santa-wide",
    theme: "dark",
    title: "Little Dreamers",
    aspectRatio: 0.45,
    subtitle: "Content, Social Media",
    categories: ["content"],
    description: "Our children are full of dreams.  In our Christmas ad campaign, Santa joins the team, listening while a child shares her dreams, and lending a hand to help them come true.",
    content: [
      { description: true },
      { text: "We received some beautiful reactions and comments with this campaign, and added thousands of users." },
      { if: "pete", text: "I created the ad campaign and managed our social media posts and advertising." },
      { photo: "content/upgift/christmas-card.jpg", aspect: 1 },
      { photo: "content/upgift/santa-upgift-tall.jpg", aspect: 2048 / 2342 },
    ]
  },
  "meme-ads": {
    name: "meme-ads",
    image: "upgift/facebook-campaigns",
    theme: "light",
    aspectRatio: 0.60,
    title: "Shopping Memes",
    subtitle: "Content, Social Media",
    categories: ["content"],
    description: "During the 2018 holiday season, a viral shopping meme emerged that was perfectly on-point.  We adjusted our messaging and advertising immediately to align with the meme.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { if: "pete", text: "I created the ad campaign and managed our social media posts and advertising." },
      { photo: "content/upgift/imsize-concert-tickets.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/imsize-ballet.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/imsize-martial-arts.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/imsize-ski.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/imsize-tattoo.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/imsize-soulcycle.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/imsize-yogaworks.jpg", aspect: 1 },
    ],
  },
  "give-experiences-ads": {
    name: "give-experiences-ads",
    image: "upgift/world-is-a-gift",
    theme: "light",
    aspectRatio: 1,
    thumb: { zoom: 1, y: 0, x: 0 },
    title: "#GiveExperiences",
    subtitle: "Content, Social Media",
    categories: ["content"],
    description: "Our social media campaigns surfed the shift in consumer attitudes toward experiences and away from planet polluting materialism, combining the two to create a fun, do-good brand.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { text: "Our social media campaigns surfed the shift in consumer attitudes toward experiences and away from planet polluting materialism, combining the two to create a fun, do-good brand." },
      { if: "pete", text: "I was responsible for all of our social media content and advertising." },
      { photo: "content/upgift/give-experiences-mystical.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/give-experiences-yoga.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/give-experiences-ski-trip.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/give-no-plastic.jpg", aspect: 1 },
      { space: 20 },
      { photo: "content/upgift/alan-walker.jpg", aspect: 1 },
    ],
  },

};
